import React from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';

export interface MinutesProp {
  title: string;
  amount: number;
}

export interface MinutesProps {
  data: MinutesProp[];
  total: string;
  title: string;
  subtitle: string;
}

const MinutesLineChart: React.FC<MinutesProps> = ({ data, title, subtitle, total }: MinutesProps) => {
  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <h3 className="tracking-tight text-base font-normal">{title}</h3>
      </div>
      <div className="p-6 pt-0">
        <div className="text-2xl font-bold">{total}</div>
        <p className="text-xs text-muted-foreground">{subtitle}</p>
        <div className="mt-4 h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 10,
                left: 10,
                bottom: 0,
              }}>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">
                              {payload[0].payload.title}
                            </span>{' '}
                            <span className="">{payload[0].value}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                }}
              />
              <Line
                type="monotone"
                dataKey="amount"
                strokeWidth={2}
                activeDot={{
                  r: 8,
                  style: { fill: 'var(--theme-primary)' },
                }}
                style={
                  {
                    stroke: 'black',
                  } as React.CSSProperties
                }
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MinutesLineChart;
